/* @import url('https://db.onlinewebfonts.com/c/f56809563b15c7c76669dac9ca9d38ac?family=Matter'); */

/* @font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
} */
/* @font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf");
} */
/* @font-face {
  font-family: Montserrat;
  src: url('./assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf');
} */

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  margin-top: 0;
  margin-bottom: 15px;
}
p {
  margin-top: 0;
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 600px) {
}

.afterChat:after {
  content: "";
  background: #4a5052;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 1200;
}
/* Changes done for edit question button in Question bank superAdmin module */
.MuiPaper-elevation24 {
  box-shadow: none !important;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: rgba(0, 0, 0, 0.08) !important;
}
.css-919eu4 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
