.box {
  margin-top: 30px;
  height: 280px;
  border-radius: 5px;
  background-image: url(../../assets/images/svgs/Bgimage.svg);
}

.reward_table {
  margin-top: 230px;
}

@media screen and (max-width: 1200px) {
  .reward_table {
    margin-top: 400px;
  }
}
