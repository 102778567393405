@import '../common';

.chatbotContainer {
  @include size(72px);
}

.avatar {
  border-radius: 50%;
  background-color: #6bc9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  @include size(72px);
  position: relative;
}

.onlineStatus {
  position: absolute;
  @include size(18px);

  right: 1px;
  bottom: 2px;
  background: #00DD66;
  border-radius: 50%;
}

.header {
  display: flex;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
  background-image: linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%);;
}

.botInfoContainer {
  margin-left: 10px;
  text-align: left;
  color: #e7e7e7;
  flex:1
}

.botName {
  font-size: 32px;
  font-weight: 600;
  font-family: 'Montserrat';
  margin: 0;
}

.botNameA {
  font-size: 40px;
}

.botInfo {
  font-size: 16px;
  font-weight: normal;
  padding-bottom: 10px;
  display: flex;
  margin-bottom: 0;
}

.avatarImage {
  @include size(96px);
}

.closeContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 25px;
}

.closeIcon {
  flex: 1;
  padding-top: 25px;
}

.headerContainer {
  padding: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  flex: 1;
}

.logoImage {
  @include size(89px, 44px);
}

.poweredByText {
  align-self: flex-end;
  margin: 0 5px 0 0;
  font-weight: 400;
  font-size: 12px;
}
