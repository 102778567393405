* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.ContainerRole {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 2%;
}
.form-GroupRole {
  background-color: #fff;
  display: flex;
  width: 25%;
  height: 42px;
  box-shadow: 0px 2px 4px 0px rgb(12 18 18 / 40%);
  /* position: relative; */
  border-radius: 5px;
  align-items: center;
  justify-content: flex-start;
  /* margin-left: 8%; */
  /* position: relative; */
}
/* .form-Group {
      background-color: #fff;
      display: flex;
      width: 100%;
      height: 40px;
      box-shadow: 0px 2px 4px 0px rgb(12 18 18 / 40%);
      position: relative;
    } */

.default-option {
  padding: 8px 18px;
}
.SearchRole {
  width: 100%;
  height: 100%;
}
.Search-inputRole {
  width: 100%;
  height: 100%;
  border: 0;
  font-size: 14px;
  border-radius: 5px;
  /* font-size: 20px; */
}
.Btn {
  cursor: pointer;
  cursor: pointer;
  height: 35px;
  width: auto;
  border: none;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
}
/* .btn {
        width: 50px;
        height: 40px;
        background-color: #f8bb2f;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        border: 10px solid #f8bb2f;
        cursor: pointer;
      } */

.search-Icon {
  color: black;
  /* color: #fff; */
}
.search-Icon:hover {
  font-size: 24px;
  color: blueviolet;
}
input:focus {
  outline: none;
}

.ClearBtn {
  cursor: pointer;
  padding: 5px;
  /* margin: auto; */
}
