
.questionContainer {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;

  & .scaleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:first-child, &:last-child {
      .scale {
        height: 40px;
        background: #767676;
        width: 2px;
      }
    }

    &:hover, &.selected {
      .scale {
        height: 50px;
        background: #000000;
        width: 2px;
      }
      & .answerValue {
        font-weight: 700;
        font-size: 50px;
      }
    }
  }

  & .scale {
    height: 30px;
    width: 1px;
    background: #8D8D8D;
  }

  & .answerValue {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 17px;
  }
}
