.input {
  position: relative;

  &.active {
    background: linear-gradient(93.39deg, #582FF9 1.3%, #AC2FF9 97.46%), #DADADA;
  }
}

.inputDisabled {
  background: rgb(218, 218, 218) !important;
}

.input > form > input[type="text"] {
  font-family: monospace;
  font-size: 16px;
  border: 0;
  height: 64px;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #eee;
  box-shadow: none;
  box-sizing: border-box;
  opacity: 1;
  outline: none;
  padding: 16px 52px 16px 30px;
  -webkit-appearance: none;
  width: 100%;
  background-color: white;
  background-color: #dadada;
  pointer-events: none;
}

.input.active > form > input[type="text"] {
  background-color: white;
  padding-left: 20px;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-left: 30px;
  border-radius: 10px;
  pointer-events: all;
}

.input > form {
  display: flex;
}

.input > form > input:focus {
  outline: none;
}

.input > form > button {
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
  padding: 20px 16px 20px 16px;
  right: 0;
  top: 0;
  position: absolute;
}

.input.active > form > button {
  position: relative;
}

