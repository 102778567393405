
.questionContainer {
  display: flex;
  justify-content: center;
}

.smiley {
  width: 96px;
  height: 116px;
  left: 112px;
  top: 337px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover,
  &.selected {
    border-radius: 10px;
    border:2px solid rgba(102, 47, 249, 1);
  }

  & .emoji { font-size: 50px }

  & .response {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
  }
}
