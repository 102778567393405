.menu-title {
  display: flex;
  align-items: center;
  color: white;
  font-size: 22px !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
  text-decoration: none;
  /* margin-left: 20px; */
  gap: 10px;
  padding: 10px 10px;
  padding-left: 10px;
  border-right: 4px solid transparent;

  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.menu-title2 {
  padding-top: 34% !important;
  /* border: 2px solid #0066b3; */
}

.link {
  display: flex;
  align-items: center;
  color: white;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.001em;
  text-decoration: none;
  gap: 100px;
  padding: 5px 10px;
  padding-left: 25px;
  /* border-bottom: 1px solid white; */
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  /* border-left: 4px solid white; */
  padding-right: 200px;
  padding-left: 25px;
  border: 5px solid white;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: 600;
  line-height: 22px;
  color: #0066b3;
  background: white;
}
