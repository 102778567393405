.app {
  /* padding: 0 35px; */
  background: #f5f5f5;
}
.permission-list-input input,
.permission-list-input .MuiInput-root {
  display: none;
}

.PhoneInputInput {
  border: none;
  font-weight: 400;
  font-size: 1rem;
}

.MuiDropzoneSnackbar-infoAlert {
  background-color: #4caf50 !important;
}

.calendarElement {
  position: absolute;
  transform: translateX(-50%);
  border: 1px solid #ccc;
  z-index: 999;
}

.calendarWrap {
  display: inline-block;
  position: relative;
}

.css-dsuxgy-MuiTableCell-root {
  font-family: Montserrat !important;
}
.css-1m13d3u-MuiTableCell-root {
  font-family: Montserrat !important;
}
.css-1yhpg23-MuiTableCell-root {
  font-family: Montserrat !important;
}
.css-3oltaz-MuiTableCell-root {
  font-family: Montserrat !important;
}
.css-xp0e6b-MuiTableCell-root {
  font-family: Montserrat !important;
}
.css-3oltaz-MuiTableCell-root {
  font-family: Montserrat !important;
}
.MuiTypography-body1 {
  font-family: Montserrat !important;
}
.css-vtdehq-MuiTableCell-root {
  font-family: Montserrat !important;
}
.css-1ex1afd-MuiTableCell-root {
  font-family: Montserrat !important;
}
.makeStyles-body-254 .MuiButtonBase-root .MuiTab-wrapper {
  font-family: Montserrat !important;
}
.makeStyles-filterName-89 {
  font-family: Montserrat !important;
}
.makeStyles-tileLabel-71 {
  font-family: Montserrat !important;
}
.makeStyles-reminderEmailTitle-91 {
  font-family: Montserrat !important;
}
.MuiTab-wrapper {
  font-family: Montserrat !important;
}
.makeStyles-tileLabel-84 {
  font-family: Montserrat !important;
}
.makeStyles-reminderEmailTitle-104 {
  font-family: Montserrat !important;
}
.css-1f7pkcr-MuiTableCell-root {
  font-family: Montserrat !important;
}
.css-1k818nl-MuiTableCell-root {
  font-family: Montserrat !important;
}
