.react-tel-input {

}

.react-tel-input .form-control {
    width: 100% !important;
    
    /* border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    border-radius: 0 !important; */
}

.react-tel-input .form-control + div:before {

    text-align: left;
    font-size: 11px !important;
    width: 50px !important;
    margin-left: -15px !important;
    color: #515151 !important;
    margin-top: -2px !important;
    padding-left: 20px !important;
    text-align: center !important;
}

.react-tel-input .form-control {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    font-size: 14px !important;
    height: "500px";
    /* margin-top: 10px; */
}

.react-tel-input .special-label {

    color: #313538;
    transform: translate(0px, -1px) scale(0.85);
}
